import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
//DB
import { fetchUsersStart } from "../../redux/users/users.actions";
import { fetchFollowRelationsStart } from "../../redux/followRelations/followRelations.actions";
//COMPONENTS
import { AddToMenuButton, SavePostButton } from "../formElements/Buttons";
import { ToastNotification } from "../genericComponents/Toasts";
//ASSETS
import { HiOutlineUser, HiOutlineCog } from "react-icons/hi";
//STYLES
import "./Cards.scss";
import { TbShare3 } from "react-icons/tb";

const currentUserSelector = (state) => state.user.currentUser;
const usersSelector = (state) => state.usersData.users;
const postsSelector = (state) => state.postsData.posts;
const followRelationsSelector = (state) =>
  state.followRelationsData.followRelations;

const mapState = createSelector(
  [currentUserSelector, usersSelector, postsSelector, followRelationsSelector],
  (currentUser, users, posts, followRelations) => ({
    currentUser,
    users,
    posts,
    followRelations,
  })
);

export function Card(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentUser, users, followRelations, posts } = useSelector(mapState);
  const { data: usersData } = users;
  const { data: postsData } = posts;
  const { data: followRelationsData } = followRelations;

  //FETCH DATA
  useEffect(() => {
    dispatch(fetchUsersStart());
    dispatch(fetchFollowRelationsStart());
  }, [dispatch]);

  //FIND USER
  const [user, setUser] = useState(null);

  useEffect(() => {
    const foundUser = usersData?.find(
      (user) => user.documentID === props.userID
    );
    setUser(foundUser);
  }, [usersData, props.userID]);

  //SHOW TOAST
  const [showSavedToastAdded, setShowSavedToastAdded] = useState(false);
  const [showSavedToastRemoved, setShowSavedToastRemoved] = useState(false);

  const [showAddedToMenuToast, setShowAddedToMenuToast] = useState(false);

  //FIND RECIPE FROM THE MENU
  const [menuItems, setMenuItems] = useState({});

  useEffect(() => {
    if (props.documentType === "menu") {
      const categories = [
        "breakfast",
        "lunch",
        "dinner",
        "snack",
        "baby",
        "pet",
      ];
      const menuData = {};

      categories.forEach((category) => {
        if (props[category]) {
          menuData[category] = props[category].map((itemId) =>
            postsData.find((post) => post.documentID === itemId)
          );
        }
      });

      setMenuItems(menuData);
    }
  }, [props, postsData]);

  //SHARE FUNCTION
  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${props.title} | Listeat.ro`,
          text: `${props.title} by ${user?.displayName} pe listeat.ro`,
          url: `https://listeat.ro/post/${props.documentID}`,
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.log("Share error", error));
    }
  };

  if (currentUser) {
    //CHECK FOLLOW RELATIONS
    const isUserFollowingOrFollower = () => {
      if (followRelationsData)
        return followRelationsData.some(
          (relation) =>
            relation.followerID === currentUser.id &&
            relation.followingID === props.userID
        );
    };

    //HIDE POST IF USER IS PRIVATE AND THERE IS NO FOLLOW RELATION
    if (
      user?.privacy === true &&
      !isUserFollowingOrFollower() &&
      currentUser.id !== props.userID
    )
      return null;

    //HIDE POST IF USER BLOCKED LIST INCLUDES CURRENT USER ID
    if (user && user?.blocked && user?.blocked.includes(currentUser.id))
      return null;

    //HIDE POST IF CURRENT USER BLOCKED LIST INCLUDES USER ID
    if (
      currentUser &&
      currentUser.blocked &&
      currentUser.blocked.includes(user?.documentID)
    )
      return null;
  }

  return (
    <>
      <tr key={props.documentID} >
        <td>
          <>
            <div className="card" value={props.documentID} id="recipeCard">
              {currentUser && (
                <>
                  {props.showMyRecipeButtons &&
                    currentUser &&
                    currentUser.id === props.userID && (
                      <Link to={`/edit-post/${props.documentID}`}>
                        <button aria-label="cardEditBtn" className="buttonEdit">
                          <span>
                            <HiOutlineCog />
                          </span>
                        </button>
                      </Link>
                    )}
                </>
              )}
              <Link to={`/post/${props.documentID}`}>
                <button aria-label="linkBtn" className="linkButton" />
              </Link>
              {props.documentType === "recipe" ? (
                <>
                  {!props.imageUrl ? (
                    <img
                      src="/assets/placeholders/ingredientPlaceholder.svg"
                      alt="Listeat"
                    />
                  ) : (
                    <img src={props.imageUrl} alt="Listeat" />
                  )}
                </>
              ) : (
                <div className="flexMenuImages">
                  <div className="flexMenuImagesScroll">
                    {[
                      "breakfast",
                      "lunch",
                      "dinner",
                      "snack",
                      "baby",
                      "pet",
                    ].map((category) => (
                      <React.Fragment key={category}>
                        {menuItems[category] &&
                          menuItems[category].map((item) => (
                            <div
                              className={`menuImageBox${
                                Object.values(menuItems).flat().length > 1
                                  ? " multiple"
                                  : ""
                              }`}
                              key={item.documentID}
                            >
                              {item && item.imageUrl ? (
                                <Link to={`/post/${props.documentID}`}>
                                  <img
                                    src={item.imageUrl}
                                    alt={item.title || "Menu item"}
                                    className="menuImage"
                                  />
                                </Link>
                              ) : (
                                <img
                                  src="/assets/placeholders/ingredientPlaceholder.svg"
                                  alt="Listeat"
                                />
                              )}
                            </div>
                          ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
              <div className="recipeTags">
                {!props.type ? null : (
                  <button
                    aria-label="postTypeBtn"
                    type="button"
                    disabled={!props.type}
                  >
                    {t(`${props.type}`)}
                  </button>
                )}
                {!props.cuisine ? null : (
                  <button
                    aria-label="postCuisineBtn"
                    type="button"
                    className="recipeCuisine"
                    disabled={!props.cuisine}
                  >
                    {t(`${props.cuisine}`)}
                  </button>
                )}
                {props.tags && props.tags.length > 0 ? (
                  <>
                    {props.tags &&
                      props.tags.map((tag) => (
                        <button
                          aria-label="postTagBtn"
                          type="button"
                          className="recipeTag"
                          disabled={!props.tags}
                          key={tag}
                        >
                          {t(`${tag}`)}
                        </button>
                      ))}
                  </>
                ) : null}
                {!props.selectedIngredients ||
                props.selectedIngredients.length < 1 ? null : (
                  <button aria-label="postIngredientsBtn" type="button">
                    {props.selectedIngredients.length} {t("ingredients")}
                  </button>
                )}
             
                {/* )}  */}
              </div>
              <div className="pageLeft">
                {/* <p className="recipeCategory">{t(`${props.category}`)}</p> */}
                {props.documentType === "recipe" ? (
                  <Link to={`/post/${props.documentID}`}>
                    <h3 className="recipeName">{props.title}</h3>
                  </Link>
                ) : (
                  <Link to={`/post/${props.documentID}`}>
                    <h3 className="recipeName">Menu by {props.userName}</h3>
                  </Link>
                )}
                <p className="recipeAuthor">
                  <HiOutlineUser />
                  {!currentUser && (
                    <Link to={`/user/${props.userID}`}>
                      <strong>{props.userName}</strong>
                    </Link>
                  )}
                  {currentUser && (
                    <>
                      {currentUser.id === props.userID ? (
                        <Link to="/account">
                          <strong>{props.userName}</strong>
                        </Link>
                      ) : (
                        <Link to={`/user/${props.userID}`}>
                          <strong>{props.userName}</strong>
                        </Link>
                      )}
                    </>
                  )}
                <span className="views">
                  {props.savedBy && props.savedBy > 0 ? (
                    <span className="viewsText">
                      {props.savedBy}
                      {props.savedBy > 1 ? (
                        <> {t("saves")}</>
                      ) : (
                        <> {t("saved")}</>
                      )}
                      &#x2022;
                   </span>
                  ) : null}
                  {!props.isViewed ? null : (
                    <>
                      {props.isViewed === 1 ? (
                        <span className="viewsText">
                          {props.isViewed} {t("view")}
                        </span>
                      ) : (
                        <span className="viewsText">
                          {props.isViewed} {t("views")}
                        </span>
                      )}
                    </>
                  )}
                </span>
                </p>
                <div className="ctaButtons">
                  {currentUser && (
                    <>
                      <SavePostButton
                        post={props.post}
                        currentUser={currentUser}
                        postID={props.documentID}
                        showSavedToastAdded={() => setShowSavedToastAdded(true)}
                        showSavedToastRemoved={() =>
                          setShowSavedToastRemoved(true)
                        }
                      />
                      <AddToMenuButton
                        postID={props.documentID}
                        showAddedToMenuToast={() =>
                          setShowAddedToMenuToast(true)
                        }
                      />
                    </>
                  )}
                  <button
                    aria-label="cardShareBtn"
                    className="buttonShare"
                    onClick={share}
                  >
                    <span>
                      <TbShare3 />
                      {t("Share")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </>
        </td>
      </tr>
      {showSavedToastAdded && (
        <ToastNotification
          content={t(
            `${props.title} added to saved ${
              props.documentType === "recipe"
                ? "recipes"
                : props.documentType === "menu"
                ? "menus"
                : props.documentType === "blog"
                ? "articles"
                : ""
            }`
          )}
        />
      )}
      {showSavedToastRemoved && (
        <ToastNotification
          content={t(
            `${props.title} removed from saved ${
              props.documentType === "recipe"
                ? "recipes"
                : props.documentType === "menu"
                ? "menus"
                : props.documentType === "blog"
                ? "articles"
                : ""
            }`
          )}
        />
      )}
      {showAddedToMenuToast && (
        <ToastNotification content={t(`${props.title} added to menu`)} />
      )}
    </>
  );
}
