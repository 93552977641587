import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//DB
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchBusinessesStart } from "../../redux/businesses/businesses.actions";
//STYLES
import "./Carrousel.scss";
import RoleIndicator from "../account/RoleIndicator";

const businessesSelector = (state) => state.businessesData.businesses;

const mapState = createSelector([businessesSelector], (businesses) => ({
  businesses,
}));

const isOpen = (business) => {
  const currentDate = new Date();
  const currentDay = currentDate.toLocaleString("en-GB", { weekday: "long" }); // Get current day in English
  const currentTime = currentDate.getHours() + currentDate.getMinutes() / 60; // Convert current time to a decimal

  const { openingTime, closingTime, workingDays } = business;

  if (!openingTime || !closingTime || !workingDays) return false;

  const [openingHour, openingMinute] = openingTime.split(":").map(Number);
  const [closingHour, closingMinute] = closingTime.split(":").map(Number);
  const openingDecimal = openingHour + openingMinute / 60;
  const closingDecimal = closingHour + closingMinute / 60;

  const isWithinWorkingDays = workingDays.includes(currentDay);
  const isWithinOpeningHours =
    currentTime >= openingDecimal && currentTime <= closingDecimal;

  return isWithinWorkingDays && isWithinOpeningHours;
};

export const BusinessesCarrousel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { businesses } = useSelector(mapState);
  const { data: businessesData } = businesses;

  useEffect(() => {
    dispatch(fetchBusinessesStart());
  }, [dispatch]);

  return (
    <div id="popularCreators">
      <h3 className="flexHeader">{t("Places")}</h3>
      <div className="carrousel">
        <div className="carrouselScroll">
          {businessesData &&
            businessesData.map((business) => {
              return (
                <div key={business.documentID} value={business.documentID}>
                  <div className="carrouselItemAccounts">
                    <Link to={`/business/${business.documentID}`}>
                      <RoleIndicator image={business.logoUrl} user={business} />
                      <h3 className="carrouselItemAccountHeader">
                        {business.businessName}
                      </h3>
                      <p className={isOpen(business) ? "textGreen" : "textRed"}>
                        {isOpen(business) ? "Open" : "Closed"}
                      </p>
                    </Link>

                    <div className="carrouselItemAccountsBtn"></div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
