import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../components/formElements/Buttons";
//LANGUAGE
import { useTranslation } from "react-i18next";
//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import { createSelector } from "reselect";

import "./Homepage.scss";
import { CustomCheckbox } from "../components/formElements/Inputs";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

SwiperCore.use([Autoplay]);

function Homepage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUser } = useSelector(mapState);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (currentUser) {
      navigate("/explore");
    }
  }, [currentUser, navigate]);

  return (
    <>
      <div className="homepage">
        <div className="homepageCenter">
          <div className="cardHomepage">
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>
              {t("What's")} <span className="textGradient">{t("cooking")}</span>{" "}
              <span>!?</span>
            </h1>
            <br />
            <h3>
              {t(
                "Transform your life with every bite: for those who know food shapes our mind, body, and world"
              )}
            </h3>
            <br />
            <br />
            <br />
            <div className="homepageButtons">
              {!currentUser ? (
                <ButtonPrimary
                  title={t("Get started for free")}
                  onClick={() => navigate("/auth")}
                  name="Authentification"
                  aria-label="Authentification button"
                />
              ) : (
                <ButtonPrimary
                  title={t("Go to App")}
                  onClick={() => navigate("/explore")}
                  name="Go to App"
                  ariaabel="Go to App button"
                />
              )}
              <Link to="/find-more">
                <ButtonSecondary
                  title={t("Find more")}
                  name="Find more"
                  aria-label="Find more button"
                />
              </Link>
            </div>
            <br />
            <br />
            <br />
            <Swiper
              direction="vertical"
              slidesPerView={5}
              loop={true}
              speed={1000}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              className="featuresSwiper"
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.realIndex);
              }}
            >
              {Array.from({ length: 13 }).map((_, index) => {
                const previousIndex = (activeIndex - 1 + 13) % 13;
                const nextIndex = (activeIndex + 1) % 13;
                const isBordered = index === nextIndex;
                const isChecked =
                  index === activeIndex ||
                  index === previousIndex ||
                  index === nextIndex;

                return (
                  <SwiperSlide
                    key={index}
                    className={`swiper-slide ${
                      isBordered ? "borderedSlide" : ""
                    } ${activeIndex === index ? "active-slide" : ""}`}
                  >
                    <span>
                      {index === 0 && t("Save your recipes")}
                      {index === 1 && t("Plan your meals")}
                      {index === 2 && t("Organize shopping list")}
                      {index === 3 && t("Compare prices")}
                      {index === 4 && t("Order groceries")}
                      {index === 5 && t("Restrict allergies")}
                      {index === 6 && t("Track nutrition")}
                      {index === 7 && t("Set dietary goals")}
                      {index === 8 && t("Reduce food waste")}
                      {index === 9 && t("Set food preferences")}
                      {index === 10 && t("Scan products")}
                      {index === 11 && t("Find restaurants")}
                      {index === 12 && t("Build your community")}
                      <CustomCheckbox checked={isChecked} />
                    </span>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
