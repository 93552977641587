import React, { Suspense, lazy, useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

//REDUX
import { useDispatch, useSelector } from "react-redux";
import { checkUserSession } from "./redux/user/user.actions";
import { WithAuth, WithBusinessAccount, WithAdminAuth } from "./hooks/hoc";

//COMPONENTS
import { ModalTooltip } from "./components/genericComponents/Modals";
import Skeleton from "./components/Skeleton";

//LAYOUTS
import { PrimaryLayout, SecondaryLayout } from "./components/Layouts";

//ASSETS
import { HiOutlineDotsVertical } from "react-icons/hi";

import Homepage from "./pages/Homepage";
import { createSelector } from "reselect";
import Tour from "./components/Tour";
import Dashboard from "./pages/Dashboard";
import BusinessMenuScanner from "./components/BusinessMenuScanner";

const BarcodeScannerContainer = lazy(() =>
  import("./components/formElements/BarcodeScanner").then((module) => ({
    default: module.BarcodeScannerContainer,
  }))
);
const Introduction = lazy(() =>
  import("./pages/Introduction").then((module) => ({
    default: module.Introduction,
  }))
);
const Test = lazy(() =>
  import("./pages/Test").then((module) => ({ default: module.Test }))
);
const Auth = lazy(() =>
  import("./pages/Auth").then((module) => ({ default: module.Auth }))
);
const UpdateIsVerified = lazy(() =>
  import("./pages/Auth").then((module) => ({
    default: module.UpdateIsVerified,
  }))
);
const RegisterSuccess = lazy(() =>
  import("./pages/Auth").then((module) => ({ default: module.RegisterSuccess }))
);
const Explore = lazy(() => import("./pages/posts/Explore"));
const PostDetails = lazy(() =>
  import("./pages/posts/PostPage").then((module) => ({
    default: module.PostDetails,
  }))
);
const FormEditRecipe = lazy(() =>
  import("./components/formElements/FormEditRecipe")
);
const FormAddPostRecipe = lazy(() =>
  import("./components/formElements/FormAddPostRecipe")
);
const FormAddPostMenu = lazy(() =>
  import("./components/formElements/FormAddPostMenu")
);
const FormAddPostIngredient = lazy(() =>
  import("./components/formElements/FormAddPostIngredient")
);
const FormAddPostArticle = lazy(() =>
  import("./components/formElements/FormAddPostArticle")
);
const MenuPlanner = lazy(() => import("./pages/planner/MenuPlanner"));
const FoodGPT = lazy(() => import("./pages/FoodGPT"));
const List = lazy(() => import("./pages/list/List"));
const ListsArchive = lazy(() => import("./pages/list/Archive"));
const RecipeCategories = lazy(() =>
  import("./pages/RecipeCategories").then((module) => ({
    default: module.RecipeCategories,
  }))
);
const RecipeCategory = lazy(() =>
  import("./pages/RecipeCategories").then((module) => ({
    default: module.RecipeCategory,
  }))
);
const UserPage = lazy(() => import("./pages/users/UserPage"));
const Users = lazy(() => import("./pages/users/Users"));
const Legal = lazy(() => import("./pages/account/settings/legal/Legal"));
const CookiePolicyAccount = lazy(() =>
  import("./pages/account/settings/legal/CookiePolicy")
);
const SecurityPolicyAccount = lazy(() =>
  import("./pages/account/settings/legal/SecurityPolicy")
);
const PrivacyPolicyAccount = lazy(() =>
  import("./pages/account/settings/legal/PrivacyPolicy")
);
const TermsOfUseAccount = lazy(() =>
  import("./pages/account/settings/legal/TermsOfUse")
);
const RefundPolicyAccount = lazy(() =>
  import("./pages/account/settings/legal/RefundPolicy")
);
const ProfessionalDashboard = lazy(() =>
  import("./pages/ProfessionalDashboard")
);
const Settings = lazy(() => import("./pages/account/settings/Settings"));
const EditProfile = lazy(() => import("./pages/account/settings/EditProfile"));
const NotificationSettings = lazy(() =>
  import("./pages/account/settings/Notifications")
);
const AccountPreferences = lazy(() =>
  import("./pages/account/settings/AccountPreferences")
);
const SecurityAndPrivacy = lazy(() =>
  import("./pages/account/settings/SecurityAndPrivacy")
);
const BlockedList = lazy(() => import("./pages/account/settings/BlockedList"));
const ConnectWallet = lazy(() =>
  import("./pages/account/settings/ConnectWallet")
);
const PaymentsAndInvocing = lazy(() =>
  import("./pages/account/settings/PaymentsAndInvocing")
);
const PaymentSuccess = lazy(() =>
  import("./components/payment/PaymentSuccess")
);
const PaymentError = lazy(() => import("./components/payment/PaymentError"));
const Places = lazy(() => import("./pages/account/Places"));
const Courses = lazy(() => import("./pages/account/Courses"));
const Help = lazy(() => import("./pages/account/settings/help/Help"));
const Contact = lazy(() => import("./pages/account/settings/Contact"));
const About = lazy(() => import("./pages/account/settings/About"));
const Account = lazy(() => import("./pages/account/Account"));
const AccountPlan = lazy(() => import("./pages/account/settings/AccountPlan"));
const Notifications = lazy(() => import("./pages/Notifications"));
const AdminHome = lazy(() => import("./pages/admin/AdminHome"));
const Page404 = lazy(() => import("./pages/Page404"));
const FormAddBusiness = lazy(() =>
  import("./components/formElements/FormAddBusiness")
);
const BusinessPage = lazy(() => import("./pages/business/BusinessPage"));
const FindMore = lazy(() => import("./pages/FindMore"));
const FindMoreNew = lazy(() => import("./pages/FindMoreNew"));
const Checkout = lazy(() => import("./components/payment/Checkout"));
const FormUpdateBusiness = lazy(() =>
  import("./components/formElements/FormUpdateBusiness")
);
const FoodItems = lazy(() => import("./pages/posts/FoodItems"));

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

function Routing() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentUser } = useSelector(mapState);

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  //SWITCH THEME
  const initialTheme = localStorage.getItem("theme") || "light";
  const [theme] = useState(initialTheme);
  useEffect(() => {
    const root = document.documentElement;
    root.classList.toggle("darkTheme", theme === "dark");
  }, [theme]);

  return (
    <Suspense fallback={<Skeleton />}>
      <Tour />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route
          exact
          path="/find-more"
          element={
            <SecondaryLayout transparent={true}>
              <FindMoreNew />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/barcode-scanner"
          element={
            <SecondaryLayout header={t("Products scanner")}>
              <BarcodeScannerContainer />
            </SecondaryLayout>
          }
        />
        <Route exact path="/find-more-old" element={<FindMore />} />
        {/* AUTH */}
        <Route
          exact
          path="/auth"
          element={
            <SecondaryLayout transparent={true} hideBackButton={true}>
              <Auth />
            </SecondaryLayout>
          }
        />
        <Route exact path="/auth/success" element={<RegisterSuccess />} />
        <Route
          exact
          path="/email-verified"
          element={
            <SecondaryLayout>
              <UpdateIsVerified />
            </SecondaryLayout>
          }
        />
        {/* PRIMARY LAYOUT */}
        <Route
          exact
          path="/dashboard"
          element={
            <PrimaryLayout withAuth={true}>
              <Dashboard />
            </PrimaryLayout>
          }
        />
        <Route
          exact
          path="/account"
          element={
            <PrimaryLayout withAuth={true}>
              <Account />
            </PrimaryLayout>
          }
        />
        <Route
          exact
          path="/business-menu-scanner"
          element={
            <SecondaryLayout header="Menu scanner" withAuth={true}>
              <BusinessMenuScanner />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/food-gpt"
          element={
            <PrimaryLayout withAuth={true}>
              <FoodGPT />
            </PrimaryLayout>
          }
        />
        <Route
          exact
          path="/explore"
          element={
            <PrimaryLayout withAuth={true}>
              <Explore />
            </PrimaryLayout>
          }
        />
        <Route
          exact
          path="/menu-planner"
          element={
            <PrimaryLayout withAuth={true}>
              <MenuPlanner />
            </PrimaryLayout>
          }
        />
        <Route
          exact
          path="/shopping-list"
          element={
            <PrimaryLayout withAuth={true}>
              <List />
            </PrimaryLayout>
          }
        />
        <Route
          exact
          path="/notifications"
          element={
            <PrimaryLayout header={t("Notifications")} withAuth={true}>
              <Notifications />
            </PrimaryLayout>
          }
        />
        {/* PAYMENT */}
        <Route
          exact
          path="/checkout"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Upgrade account")}>
                <Checkout />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/payment-success"
          element={
            <SecondaryLayout header={t("Update account")}>
              <PaymentSuccess />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/payment-error"
          element={
            <SecondaryLayout header={t("Update account")}>
              <PaymentError />
            </SecondaryLayout>
          }
        />
        {/* DASHBOARD */}
        <Route
          exact
          path="/professional-dashboard"
          element={
            <SecondaryLayout header={t("Professional dashboard")}>
              <ProfessionalDashboard />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/shopping-list/archive"
          element={
            <SecondaryLayout header={t("Shopping lists archive")}>
              <ListsArchive />
            </SecondaryLayout>
          }
        />
        {/* USERS */}
        <Route
          exact
          path="/users"
          element={
            <SecondaryLayout header={t("Users")}>
              <Users />
            </SecondaryLayout>
          }
        />
        <Route exact path="/user/:userID" element={<UserPage />} />
        {/* RECIPE CATEGORIES */}
        <Route
          exact
          path="/recipe-categories"
          element={
            <SecondaryLayout header={t("Recipe categories")}>
              <RecipeCategories />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/recipe-categories/:link"
          element={<RecipeCategory />}
        />
        {/* POST PAGE */}
        <Route
          exact
          path="/post/:postID"
          element={
            <SecondaryLayout
              transparent={true}
              hideBackButton={!currentUser ? true : false}
            >
              <PostDetails />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/edit-post/:documentID"
          element={
            <WithAuth>
              <FormEditRecipe />
            </WithAuth>
          }
        />
        {/* ADD FORMS */}
        <Route
          exact
          path="/add-recipe/"
          element={
            <WithAuth>
              <FormAddPostRecipe />
            </WithAuth>
          }
        />
        <Route
          exact
          path="/add-menu/"
          element={
            <WithAuth>
              <FormAddPostMenu />
            </WithAuth>
          }
        />
        <Route
          exact
          path="/add-ingredient/"
          element={
            <WithAuth>
              <FormAddPostIngredient />
            </WithAuth>
          }
        />
        <Route
          exact
          path="/add-article"
          element={
            <WithAdminAuth>
              <SecondaryLayout transparent={true}>
                <FormAddPostArticle />
              </SecondaryLayout>
            </WithAdminAuth>
          }
        />
        {/* ACCOUNT PAGES */}
        <Route
          exact
          path="/account/settings"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Settings")}>
                <Settings />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/account/account-preferences"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Account preferences")}>
                <AccountPreferences />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/account/security-privacy"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Security and privacy")}>
                <SecurityAndPrivacy />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/account/notifications"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Notifications")}>
                <NotificationSettings />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/account/connect-wallet"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Connect wallet")}>
                <ConnectWallet />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/account/payments-and-invoicing"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Payments and invoices")}>
                <PaymentsAndInvocing />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/account/edit-profile"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Edit profile")}>
                <EditProfile />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/places"
          element={
            <SecondaryLayout header={t("Places")}>
              <Places />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/courses"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Courses")}>
                <Courses />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/account-plans"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Account type")}>
                <AccountPlan />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        {/* BUSINESS */}

        <Route
          exact
          path="/add-new-business"
          element={
            <WithBusinessAccount>
              <SecondaryLayout transparent={true}>
                <FormAddBusiness />
              </SecondaryLayout>
            </WithBusinessAccount>
          }
        />
        <Route
          exact
          path="/business/:businessID"
          element={
            <SecondaryLayout transparent={true}>
              <BusinessPage />
            </SecondaryLayout>
          }
        />
        <Route
          path="/update-business/:businessID"
          element={
            <SecondaryLayout header={t("Update business")}>
              <FormUpdateBusiness />
            </SecondaryLayout>
          }
        />
        {/* HELP */}
        <Route
          exact
          path="/help"
          element={
            <SecondaryLayout header={t("Help")}>
              <Help />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <SecondaryLayout header={<>{t("About")}</>}>
              <About />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <SecondaryLayout header={<>{t("Contact")}</>}>
              <Contact />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/account/block-list"
          element={
            <SecondaryLayout header={t("Blocked accounts")}>
              <BlockedList />
            </SecondaryLayout>
          }
        />
        {/* TERMS AND POLICIES*/}
        <Route
          exact
          path="/terms-and-policies"
          element={
            <SecondaryLayout header={t("Terms and policies")}>
              <Legal />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <SecondaryLayout
              header={
                <>
                  {t("Privacy policy")}
                  <ModalTooltip
                    title={<HiOutlineDotsVertical />}
                    content={
                      <>
                        <NavLink activeclassname="active" to="/terms-of-use">
                          <button>{t("Terms of use")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/privacy-policy">
                          <button>{t("Privacy policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/refund-policy">
                          <button>{t("Refund policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/cookie-policy">
                          <button>{t("Cookie policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/security-policy">
                          <button>{t("Security policy")}</button>
                        </NavLink>
                      </>
                    }
                  />
                </>
              }
            >
              <PrivacyPolicyAccount />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/terms-of-use"
          element={
            <SecondaryLayout
              header={
                <>
                  {t("Terms of use")}
                  <ModalTooltip
                    title={<HiOutlineDotsVertical />}
                    content={
                      <>
                        <NavLink activeclassname="active" to="/terms-of-use">
                          <button>{t("Terms of use")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/privacy-policy">
                          <button>{t("Privacy policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/refund-policy">
                          <button>{t("Refund policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/cookie-policy">
                          <button>{t("Cookie policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/security-policy">
                          <button>{t("Security policy")}</button>
                        </NavLink>
                      </>
                    }
                  />
                </>
              }
            >
              <TermsOfUseAccount />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/refund-policy"
          element={
            <SecondaryLayout
              header={
                <>
                  {t("Refund policy")}
                  <ModalTooltip
                    title={<HiOutlineDotsVertical />}
                    content={
                      <>
                        <NavLink activeclassname="active" to="/terms-of-use">
                          <button>{t("Terms of use")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/privacy-policy">
                          <button>{t("Privacy policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/refund-policy">
                          <button>{t("Refund policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/cookie-policy">
                          <button>{t("Cookie policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/security-policy">
                          <button>{t("Security policy")}</button>
                        </NavLink>
                      </>
                    }
                  />
                </>
              }
            >
              <RefundPolicyAccount />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/cookie-policy"
          element={
            <SecondaryLayout
              header={
                <>
                  {t("Cookie policy")}
                  <ModalTooltip
                    title={<HiOutlineDotsVertical />}
                    content={
                      <>
                        <NavLink activeclassname="active" to="/terms-of-use">
                          <button>{t("Terms of use")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/privacy-policy">
                          <button>{t("Privacy policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/refund-policy">
                          <button>{t("Refund policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/cookie-policy">
                          <button>{t("Cookie policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/security-policy">
                          <button>{t("Security policy")}</button>
                        </NavLink>
                      </>
                    }
                  />
                </>
              }
            >
              <CookiePolicyAccount />
            </SecondaryLayout>
          }
        />
        <Route
          exact
          path="/security-policy"
          element={
            <SecondaryLayout
              header={
                <>
                  {t("Security policy")}
                  <ModalTooltip
                    title={<HiOutlineDotsVertical />}
                    content={
                      <>
                        <NavLink activeclassname="active" to="/terms-of-use">
                          <button>{t("Terms of use")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/privacy-policy">
                          <button>{t("Privacy policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/refund-policy">
                          <button>{t("Refund policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/cookie-policy">
                          <button>{t("Cookie policy")}</button>
                        </NavLink>
                        <NavLink activeclassname="active" to="/security-policy">
                          <button>{t("Security policy")}</button>
                        </NavLink>
                      </>
                    }
                  />
                </>
              }
            >
              <SecurityPolicyAccount />
            </SecondaryLayout>
          }
        />

        {/* ADMIN */}
        <Route
          exact
          path="/admin-panel"
          element={
            <WithAdminAuth>
              <SecondaryLayout header={t("Admin panel")}>
                <AdminHome />
              </SecondaryLayout>
            </WithAdminAuth>
          }
        />
        {/* TEST */}
        <Route
          exact
          path="/test"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Test")}>
                <Test />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route
          exact
          path="/introduction"
          element={
            <WithAuth>
              <SecondaryLayout header={t("Introduction")}>
                <Introduction />
              </SecondaryLayout>
            </WithAuth>
          }
        />
        <Route exact path="/foods" element={<FoodItems />} />

        {/* 404 PAGE NOT FOUND */}
        <Route
          exact
          path="*"
          element={
            <SecondaryLayout>
              <Page404 />
            </SecondaryLayout>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default Routing;
